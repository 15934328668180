<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="페기물 처리결과" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && popupParam.envWasteAllbaroId"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="인계번호"
                  name="envWasteAllbaroNo"
                  v-model="data.envWasteAllbaroNo">
                </c-text>
              </div>
              <div class="col-3">
                <c-datepicker
                  :required="true"
                  label="인계일자"
                  name="allbaroDt"
                  v-model="data.allbaroDt"
                />
              </div>
              <div class="col-6">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="폐기물종류(성상)"
                  name="wasteType"
                  v-model="data.wasteType">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="위탁량"
                  name="wasteVolume"
                  v-model="data.wasteVolume">
                </c-text>
              </div>
              <div class="col-2">
                <c-select
                  :required="true"
                  :comboItems="wasteVolumeUnitItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="wasteVolumeUnit"
                  label="LBLUNIT"
                  v-model="data.wasteVolumeUnit"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="운반자명"
                  name="transportName"
                  v-model="data.transportName">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="처리자명"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="처리방법"
                  name="processMethod"
                  v-model="data.processMethod">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="관할관청"
                  name="officeName"
                  v-model="data.officeName">
                </c-text>
              </div>
              <div class="col-3">
                <c-field
                  :required="true"
                  :editable="editable && !popupParam.envWasteAllbaroId"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성부서 & 작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-3">
                <c-plant :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'waste-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envWasteAllbaroId: '',
          plantCd: null,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      endFlagItems: [
        {
          code: '예',
          codeName: '예',
        },
        {
          code: '아니오',
          codeName: '아니오',
        },
      ],
      emitterFlagItems: [
        {
          code: '예',
          codeName: '예',
        },
        {
          code: '아니오',
          codeName: '아니오',
        },
      ],
      ipItems: [
        {
          code: 'Y',
          codeName: 'Y',
        },
        {
          code: 'N',
          codeName: 'N',
        },
      ],
      wasteVolumeUnitItems: [
        {
          code: 'kg',
          codeName: 'kg',
        },
      ],
      saveUrl: transactionConfig.env.waste.allbaro.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      checkUrl: '',
      emptylUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      data: {
        envWasteAllbaroId: '',  // 폐기물 인계서 시스템일련번호
        plantCd: null,  // 사업장
        userId: '',
        envWasteAllbaroNo: '',  // 인계번호
        allbaroDt: '',  // 인계일자
        endFlag: null,  // 확정여부
        wasteType: '',  // 폐기물종류
        wasteTypeCd: '',  // 구분
        wasteVolume: '',  // 위탁량
        wasteVolumeUnit: null,  // 단위
        wasteVolumeInsu: '',  // 처리자인수량
        transportName: '',  // 운반자명
        carNo: '',  // 차량번호
        processName: '',  // 처리자명
        processMethod: '',  // 처리방법
        processLocation: '',  // 처리장소
        transferName: '',  // 인계자명
        emitterFlag: null,  // 공동배출자여부
        inputFlag: '',  // 입력구분
        officeName: '',  // 관할관청
        status: '',  // 진행상태
        processResult: '',  // 처리결과
        transportIp: 'Y',  // 운반IP동일
        processIp: 'Y',  // 처리IP동일
        remark: '',  // 예외사유
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.waste.allbaro.get.url;
      this.insertUrl = transactionConfig.env.waste.allbaro.insert.url;
      this.updateUrl = transactionConfig.env.waste.allbaro.update.url;
      this.deleteUrl = transactionConfig.env.waste.allbaro.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.envWasteAllbaroId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envWasteAllbaroId: this.popupParam.envWasteAllbaroId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
        },);
      } else {
        this.data.userId = this.$store.getters.user.userId;
        this.data.plantCd = this.popupParam.plantCd;
      }
    },
    saveInfo() {
      if (this.popupParam.envWasteAllbaroId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info', // success / info / warning / error
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envWasteAllbaroId = result.data.envWasteAllbaroId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.envWasteAllbaroId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>