var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "페기물 처리결과" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.popupParam.envWasteAllbaroId
                            ? _c("c-btn", {
                                attrs: { label: "LBLREMOVE", icon: "remove" },
                                on: { btnClicked: _vm.deleteData },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "인계번호",
                            name: "envWasteAllbaroNo",
                          },
                          model: {
                            value: _vm.data.envWasteAllbaroNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "envWasteAllbaroNo", $$v)
                            },
                            expression: "data.envWasteAllbaroNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            label: "인계일자",
                            name: "allbaroDt",
                          },
                          model: {
                            value: _vm.data.allbaroDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "allbaroDt", $$v)
                            },
                            expression: "data.allbaroDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "폐기물종류(성상)",
                            name: "wasteType",
                          },
                          model: {
                            value: _vm.data.wasteType,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "wasteType", $$v)
                            },
                            expression: "data.wasteType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            type: "number",
                            required: true,
                            editable: _vm.editable,
                            label: "위탁량",
                            name: "wasteVolume",
                          },
                          model: {
                            value: _vm.data.wasteVolume,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "wasteVolume", $$v)
                            },
                            expression: "data.wasteVolume",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            required: true,
                            comboItems: _vm.wasteVolumeUnitItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "wasteVolumeUnit",
                            label: "LBLUNIT",
                          },
                          model: {
                            value: _vm.data.wasteVolumeUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "wasteVolumeUnit", $$v)
                            },
                            expression: "data.wasteVolumeUnit",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "운반자명",
                            name: "transportName",
                          },
                          model: {
                            value: _vm.data.transportName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "transportName", $$v)
                            },
                            expression: "data.transportName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "처리자명",
                            name: "processName",
                          },
                          model: {
                            value: _vm.data.processName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processName", $$v)
                            },
                            expression: "data.processName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "처리방법",
                            name: "processMethod",
                          },
                          model: {
                            value: _vm.data.processMethod,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processMethod", $$v)
                            },
                            expression: "data.processMethod",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관할관청",
                            name: "officeName",
                          },
                          model: {
                            value: _vm.data.officeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "officeName", $$v)
                            },
                            expression: "data.officeName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            editable:
                              _vm.editable && !_vm.popupParam.envWasteAllbaroId,
                            data: _vm.data,
                            deptValue: "deptCd",
                            type: "dept_user",
                            label: "작성부서 & 작성자",
                            name: "userId",
                          },
                          model: {
                            value: _vm.data.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "userId", $$v)
                            },
                            expression: "data.userId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }